import React, { useState, useEffect } from 'react';
import './Home.css';
import { Button, MenuItem, Container, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import maintenance from './pictures/maintenance.png';
import AvailProp from './pictures/AvailProp.png';
import TenApp from './pictures/TenApp.png';
import rightArrow from './pictures/rightArrow.png';
import medPic from './pictures/PAMed.png';
import intPic from './pictures/int.png';
import jHouse2 from './pictures/jHouse2.png';
import hillcrest from './pictures/Hillcrest.png';
import { Helmet } from 'react-helmet-async'

const Home = () => {
  const images = [hillcrest, jHouse2, medPic, intPic];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(false);

  const nextSlide = () => {
    setFade(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      setFade(false);
    }, 500); // Time for fade-out before changing image
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 5800); // Change every 7 seconds
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Preload the first image
    const img = new Image();
    img.src = images[0];
  }, [images]);

  return (
    <div className="home-container">
      <div className="preload-image"></div>
      <div className="preload-image1"></div>
      <div className="preload-image2"></div>
      <div className="preload-image3"></div>
      <Helmet>
        <head>Delta Property Management Group</head>
        <meta name="description" content="Welcome to Delta Property Management Group, LLC. 
        A Harrisburg Area Property Management Group. Delta PMG is a reliable partner that offers
        services for landlords by landlords. We specialize in managing residential properties aswell 
        as commercial properties."/>
        <link rel="canonical" href="/" />
      </Helmet>
      {/* Hero Section */}
      <section className="hero">
        {images.map((image, index) => (
          <img
            key={index}
            className={`slide ${index === currentIndex ? 'active' : ''} ${fade ? 'fade-out' : 'fade-in'}`}
            src={image}
            alt={`Slide ${index + 1}`}
          />
        ))}

        <Icon
          name="chevron left"
          className="left-arrow"
          onClick={nextSlide}
        />
        <Icon
          name="chevron right"
          className="right-arrow"
          onClick={nextSlide}
        />

        <div className="overlay">
          <Container>
            <div className="ui grid" style={{ minHeight: 270 }}>
              <div className="sixteen wide column">
                <h1 className="font-1">Welcome to Delta Property Management Group, LLC.</h1>
                <h2 className="font-2">Harrisburg, Pennsylvania Property Management</h2>
                <Link to="/pricing">
                  <Button className="pButton">
                    Property Management Pricing
                    <img
                      className="aIcon"
                      src={rightArrow}
                      style={{ marginLeft: '10px', width: '16px', height: '17px' }}
                      alt="Arrow Icon"
                    />
                  </Button>
                </Link>
              </div>
            </div>
          </Container>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="contact">
        <Container text>
          <h3>Contact Us</h3>
          <Link to="/contact-us">
            <Button primary size="large" className="contact-button">
              Click here to send us a message!
            </Button>
          </Link>
        </Container>
      </section>

      {/* Other sections */}
      <section>
        <Container textAlign="center" className="c-width">
          <div className="ui three column grid">
            <div className="column">
              <MenuItem
                className="logos"
                as={Link}
                style={{ fontSize: '115%' }}
                to="https://signin.managebuilding.com/manager/public/authentication/login"
              >
                <img src={maintenance} alt="Maintenance Request" className="blogo" style={{ cursor: 'pointer' }} />
                <h4 className="del-white">Maintenance Request</h4>
              </MenuItem>
            </div>
            <div className="column">
              <MenuItem
                className="logos"
                as={Link}
                style={{ fontSize: '115%' }}
                to="https://deltapmg.managebuilding.com/Resident/public/home"
              >
                <img src={AvailProp} alt="Available Properties" className="blogo" style={{ cursor: 'pointer' }} />
                <h4 className="del-white">Available Properties</h4>
              </MenuItem>
            </div>
            <div className="column">
              <MenuItem
                className="logos"
                as={Link}
                style={{ fontSize: '115%' }}
                to="https://deltapmg.managebuilding.com/Resident/rental-application/new/apply"
              >
                <img src={TenApp} alt="Tenant Application" className="blogo" style={{ cursor: 'pointer' }} />
                <h4 className="del-white">Tenant Application</h4>
              </MenuItem>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Home;
